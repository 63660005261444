// src/components/ProtectedRoute.jsx
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext'; 

const ProtectedRoute = ({ children }) => {
    const { user } = useAuth(); 

    // If user is not authenticated, redirect to login page
    if (!user) {
        return <Navigate to="/login" replace />;
    }

    return children; 
};

export default ProtectedRoute;
