import React from 'react';
import { Link } from 'react-router-dom';

const Home = () => {
  return (
    <div>
      <section className="bg-white w-full dark:bg-gray-900 h-full">
        <div className="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
          {/* Flex container for responsive layout */}
          <div className="flex flex-col lg:flex-row lg:col-span-12">
            {/* Image on mobile, next to text on larger screens */}
            <div className="order-1 lg:order-2 lg:mt-0 lg:col-span-5 mt-10 flex justify-center items-center">
              <img
                src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/hero/phone-mockup.png"
                alt="mockup"
              />
            </div>
            <div className="mr-auto place-self-center lg:col-span-7 order-2 lg:order-1">
              <h1 className="max-w-2xl mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl dark:text-white">
                BWM Employee Management Portal
              </h1>
              <p className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">
              An integrated web-based solution for managing employees and assets at Broadwater Marine Inc.
              </p>
              <Link to="/login" className="inline-flex text-white bg-primary-600 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-3 text-center dark:focus:ring-primary-900 my-4">
          Sign in
          <svg
                  className="w-5 h-5 ml-2 -mr-1"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
        </Link>

             
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
