import React from 'react';
import development from '../assets/development.svg';
import { Link } from 'react-router-dom';

const TodoList = () => {
  return (
    <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6 ">
        <div class="mx-auto max-w-screen-sm text-center">
        <div className="flex justify-center items-center">
      <img 
        src={development}
        alt="Example" 
        className="w-72 h-auto rounded-lg"
      />
    </div>
            <p class="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl dark:text-white">Page Under Development</p>
            <p class="mb-4 text-lg font-light text-gray-500 dark:text-gray-400"> We are working hard to get this page ready for you. Stay tuned!</p>
            <p class="mb-4 text-lg font-light text-gray-500 dark:text-gray-400">-Enrique</p>
            <Link to="/profile" className="inline-flex text-white bg-primary-600 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-primary-900 my-4">
          Back to Profile
        </Link>
        </div>   
    </div>

  );
};

export default TodoList;
