import React, { useEffect, useRef } from 'react';
import { ref, set, get } from 'firebase/database';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { database, auth } from '../firebase';

const Register = () => {
  const email = 'manager@example.com'; // Unique email
  const password = 'securePassword123'; // Secure password
  const userCreatedRef = useRef(false); // Track if user is created

  useEffect(() => {
    const userData = {
      firstName: 'Alice', // First name
      lastName: 'Smith',  // Last name
      phoneNumber: '555-0123', // Phone number
      dateOfBirth: '1992-02-15', // Date of birth
      hireDate: '2024-02-01', // Hire date
      department: 'HR', // Department
      position: 'Manager', // Position
      salary: '70000', // Salary
      address: '789 Oak St, City, Country', // Address
      status: 'Active', // Status
      role: 'admin' // Setting the role here
    };

    const addUser = async () => {
      // Check if user has already been created
      if (userCreatedRef.current) return;

      try {
        const userRef = ref(database, 'users');
        const snapshot = await get(userRef);
        
        const existingUser = snapshot.val() && Object.values(snapshot.val()).some(user => user.email === email);
        
        if (existingUser) {
          console.log('User already exists, skipping creation.');
          return; // Exit if user already exists
        }

        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;

        await set(ref(database, 'users/' + user.uid), {
          email: user.email,
          ...userData,
        });

        console.log('User created successfully:', user);
        console.log('User Role:', userData.role); // Log the role
        userCreatedRef.current = true; // Update ref to indicate user creation
      } catch (error) {
        console.error('Error during user registration:', error);
      }
    };

    addUser();

  }, []); // Empty dependency array to ensure this effect only runs once

  return (
    <div>
      <h1>User Registration</h1>
      <p>Check the console for registration status and role.</p>
    </div>
  );
};

export default Register;
