import React, { useEffect, useState } from 'react';
import { getAuth } from 'firebase/auth';
import { getDatabase, ref, get } from 'firebase/database';
/* import { Link } from 'react-router-dom'; */
import { FaFacebook } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { FaMobileAlt } from "react-icons/fa";

const Profile = () => {
    const [userDetails, setUserDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const auth = getAuth();
    const database = getDatabase();

    useEffect(() => {
        const fetchUserData = async () => {
            const user = auth.currentUser; 
            if (user) {
                try {

                    const userRef = ref(database, 'users/' + user.uid);

                    const snapshot = await get(userRef);
                    if (snapshot.exists()) {
                        setUserDetails(snapshot.val()); 
                    } else {
                        console.log('No user data found.');
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                } finally {
                    setLoading(false); 
                }
            } else {
                console.log('No authenticated user found.');
                setLoading(false);
            }
        };

        fetchUserData();
    }, [auth, database]);

    return (
        <div className="">
            {loading ? (
                <p>Loading user details...</p>
            ) : userDetails ? (
              <div className="grid grid-cols-1 px-2  xl:grid-cols-3 xl:gap-4 dark:bg-gray-900">
              <div className="mb-4 col-span-full xl:mb-0">
              
              </div>
           
              <div className="col-span-full xl:col-auto">
                  <div className="p-4 mb-4 bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6 dark:bg-gray-800">
                      <div className="items-center sm:flex xl:block 2xl:flex sm:space-x-4 xl:space-x-0 2xl:space-x-4">
                      
                          <div>
                          <div class="flex space-x-4">
            <img class="h-24 w-24 mb-5 rounded-lg" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/helene-engels.png" alt="Helene avatar" />
            <div>
              <span class="mb-2 inline-block rounded bg-primary-100 px-2.5 py-0.5 text-xs font-medium text-primary-800 dark:bg-primary-900 dark:text-primary-300">Admin</span>
              <h2 class="flex items-center text-xl font-bold leading-none text-gray-900 dark:text-white sm:text-2xl">{userDetails.firstName}  {userDetails.lastName}</h2>
            </div>
          </div>
                              <div className="mb-4 text-sm text-gray-500 dark:text-gray-400">
                                  JPG, GIF or PNG. Max size of 800K
                              </div>
                              <div className="flex items-center space-x-4">
                                  <button type="button" className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">
                                      <svg className="w-4 h-4 mr-2 -ml-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M5.5 13a3.5 3.5 0 01-.369-6.98 4 4 0 117.753-1.977A4.5 4.5 0 1113.5 13H11V9.413l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L9 9.414V13H5.5z"></path><path d="M9 13h2v5a1 1 0 11-2 0v-5z"></path></svg>
                                      Upload picture
                                  </button>
                                  <button type="button" className="py-2 px-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                                      Delete
                                  </button>
                              </div>
                          </div>
                      </div>
                  </div>
          
                  <div className="p-4 mb-4 bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6 dark:bg-gray-800">
                      <div className="flow-root">
                          <h3 className="text-xl font-semibold dark:text-white">Contact Information</h3>
                          <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                              <li className="py-4">
                                  <div className="flex items-center space-x-4">
                                      <div className="flex-shrink-0">
                                      <MdEmail />
                                      </div>
                                      <div className="flex-1 min-w-0">
                                          <span className="block text-base font-semibold text-gray-900 truncate dark:text-white">
                                              Email
                                          </span>
                                           <a href="wwww.broadwatermarine.com" className="block text-sm font-normal truncate text-primary-700 hover:underline dark:text-primary-500"> 
                                           
                                          </a>
                                      </div>
                                      <div className="inline-flex items-center">
                                          <a href="wwww.broadwatermarine.com" className="px-3 py-2 mb-3 mr-3 text-sm font-medium text-center text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:ring-primary-300 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Edit</a>
                                      </div>
                                  </div>
                              </li>
                              <li className="py-4">
                                  <div className="flex items-center space-x-4">
                                      <div className="flex-shrink-0">
                                      <FaMobileAlt />
                                      </div>
                                      <div className="flex-1 min-w-0">
                                          <span className="block text-base font-semibold text-gray-900 truncate dark:text-white">
                                              Phone Number
                                          </span>
                                           <input className="block text-sm font-normal truncate text-primary-700 hover:underline dark:text-primary-500">
                                              
                                          </input>
                                      </div>
                                      <div className="inline-flex items-center">
                                          <a href="wwww.broadwatermarine.com"className="px-3 py-2 mb-3 mr-3 text-sm font-medium text-center text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:ring-primary-300 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Edit</a>
                                      </div>
                                  </div>
                              </li>
                              <li className="py-4">
                                  <div className="flex items-center space-x-4">
                                      <div className="flex-shrink-0">
                                      <FaFacebook />
                                      </div>
                                      <div className="flex-1 min-w-0">
                                          <span className="block text-base font-semibold text-gray-900 truncate dark:text-white">
                                             Facebook
                                          </span>
                                           <input className="block text-sm font-normal truncate text-primary-700 hover:underline dark:text-primary-500">
                                              
                                          </input>
                                      </div>
                                      <div className="inline-flex items-center">
                                          <a href="wwww.broadwatermarine.com" className="px-3 py-2 mb-3 mr-3 text-sm font-medium text-center text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:ring-primary-300 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Edit</a>
                                      </div>
                                  </div>
                              </li>
                          </ul>
                         
                      </div>
                  </div>
              </div>
              <div className="col-span-2">
                  <div className="p-2 mb-4 bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6 dark:bg-gray-800">
                      <h3 className="mb-4 text-xl font-semibold dark:text-white">User information</h3>
                      <form action="#">
                        <div className="grid grid-cols-6 gap-6">
                          <div className="col-span-6 sm:col-span-3 border border-gray-300 rounded-lg p-2.5">
                            <label htmlFor="first-name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">First Name</label>
                            <p className="text-gray-900 dark:text-white">{userDetails.firstName}</p>
                          </div>
                          <div className="col-span-6 sm:col-span-3 border border-gray-300 rounded-lg p-2.5">
                            <label htmlFor="last-name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Last Name</label>
                            <p className="text-gray-900 dark:text-white">{userDetails.lastName}</p>
                          </div>
                          <div className="col-span-6 sm:col-span-3 border border-gray-300 rounded-lg p-2.5">
                            <label htmlFor="country" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Department</label>
                            <p className="text-gray-900 dark:text-white">{userDetails.department}</p>
                          </div>
                          <div className="col-span-6 sm:col-span-3 border border-gray-300 rounded-lg p-2.5">
                            <label htmlFor="city" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Position</label>
                            <p className="text-gray-900 dark:text-white">{userDetails.position}</p>
                          </div>
                          <div className="col-span-6 sm:col-span-3 border border-gray-300 rounded-lg p-2.5">
                            <label htmlFor="address" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Branch</label>
                            <p className="text-gray-900 dark:text-white">Subic</p>
                          </div>
                          <div className="col-span-6 sm:col-span-3 border border-gray-300 rounded-lg p-2.5">
                            <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Employee ID</label>
                            <p className="text-gray-900 dark:text-white">{userDetails.employeeID}</p>
                          </div>
                          <div className="col-span-6 sm:col-span-3 border border-gray-300 rounded-lg p-2.5">
                            <label htmlFor="phone-number" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Date Hired</label>
                            <p className="text-gray-900 dark:text-white">{userDetails.hireDate}</p>
                          </div>
                          <div className="col-span-6 sm:col-span-3 border border-gray-300 rounded-lg p-2.5">
                            <label htmlFor="birthday" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Birthday</label>
                            <p className="text-gray-900 dark:text-white">{userDetails.birthDate}</p>
                          </div>
                        </div>
                      </form>


                  </div>
           
                  <div className="p-4 mb-4 bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6 dark:bg-gray-800">
                      <div className="flow-root">
                          <h3 className="text-xl font-semibold dark:text-white">Assigned Assets</h3>
                          <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                              <li className="py-4">
                                  <div className="flex items-center space-x-4">
                                      <div className="flex-shrink-0">
                                          <svg className="w-6 h-6 dark:text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"></path></svg>
                                      </div>
                                      <div className="flex-1 min-w-0">
                                          <p className="text-base font-semibold text-gray-900 truncate dark:text-white">
                                              Samsung a22 5g
                                          </p>
                                          <p className="text-sm font-normal text-gray-500 truncate dark:text-gray-400">
                                              FF0020
                                          </p>
                                      </div>
                                      <div className="inline-flex items-center">
                                          <a href="wwww.broadwatermarine.com" className="px-3 py-2 mb-3 mr-3 text-sm font-medium text-center text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:ring-primary-300 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">View</a>
                                      </div>
                                  </div>
                              </li>
                      
                          </ul>
                       
                      </div>
                  </div>
              </div>
              
          </div>
          
             /*    <div>
                    <h2>User Profile</h2>
                    <p><strong>Email:</strong></p>
                    <p><strong>Employee ID:</strong> {userDetails.employeeId}</p>
                    <p><strong>First Name:</strong> {userDetails.firstName}</p>
                    <p><strong>Last Name:</strong> {userDetails.lastName}</p>
                    <p><strong>Phone Number:</strong> {userDetails.phoneNumber}</p>
                    <p><strong>Date of Birth:</strong> {userDetails.dateOfBirth}</p>
                    <p><strong>Hire Date:</strong> {userDetails.hireDate}</p>
                    <p><strong>Department:</strong> {userDetails.department}</p>
                    <p><strong>Position:</strong> {userDetails.position}</p>
                    <p><strong>Address:</strong> {userDetails.address}</p>
                </div> */
            ) : (
                <p>No user data found.</p>
            )}
        </div>
    );
};

export default Profile;
