import { Routes, Route } from 'react-router-dom';
import Profile from '../pages/Profile'; 
import Announcements from '../pages/Announcements'; 
import Attendance from '../pages/Attendance'; 
import ToDoList from '../pages/ToDoList'; 
import Settings from '../pages/Settings'; 
import Resources from '../pages/Resources'; 
import About from '../pages/About'; 
import Dashboard from '../pages/Dashboard';
import Home from '../pages/Home';

const Main = () => {
  return (
    <div className="pl-4 pr-4 pt-20 sm:pl-10 sm:pr-10 w-full h-full" >
      <Routes>
        <Route path="/profile" element={<Profile />} /> 
        <Route path="/announcements" element={<Announcements />} /> 
        <Route path="/attendance" element={<Attendance />} /> 
        <Route path="/todo" element={<ToDoList />} /> 
        <Route path="/settings" element={<Settings />} /> 
        <Route path="/resources" element={<Resources />} />
        <Route path="/about" element={<About />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/home" element={<Home />} />
      </Routes>
    </div>
  );
};

export default Main;
