// src/App.jsx
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import Main from './components/Main';
import Login from './pages/Login'; 
import Register from './pages/Register'; 
import Dashboard from './pages/Dashboard'; 

import { AuthProvider } from './context/AuthContext'; 
import ProtectedRoute from './components/ProtectedRoute'; 

const AppRoutes = () => {
    const location = useLocation();
    const isAuthRoute = location.pathname === '/' || location.pathname === '/register' || location.pathname === '/login';

    return (
        <div className="app flex h-screen">
            {!isAuthRoute && <Sidebar />} 
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route 
                    path="/dashboard" 
                    element={
                        <ProtectedRoute>
                            <Dashboard />
                        </ProtectedRoute>
                    } 
                />
                {/* Protect all other routes under Main */}
                <Route 
                    path="*" 
                    element={
                        <ProtectedRoute>
                            <Main />
                        </ProtectedRoute>
                    } 
                />
            </Routes>
        </div>
    );
};

function App() {
    return (
        <AuthProvider>
            <Router>
                <AppRoutes />
            </Router>
        </AuthProvider>
    );
}

export default App;
