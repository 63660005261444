
import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
import { getAuth } from "firebase/auth";


const firebaseConfig = {
  apiKey: "AIzaSyDJ9-DowesMKt7mIdiv1ZNijPj85TnGtDo",
  authDomain: "broadwater-marine.firebaseapp.com",
  databaseURL: "https://broadwater-marine-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "broadwater-marine",
  storageBucket: "broadwater-marine.appspot.com",
  messagingSenderId: "860429177854",
  appId: "1:860429177854:web:de8b8d18992ddc56bfe6af",
  measurementId: "G-B17F4CHEHD"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const auth = getAuth(app);


export { app, database, auth };
