// AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth } from '../firebase'; // Ensure you have the correct import
import { signInWithEmailAndPassword, onAuthStateChanged, signOut } from 'firebase/auth';
import { getDatabase, ref, get } from 'firebase/database'; // Import Realtime Database functions

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [role, setRole] = useState(null); // State to hold user role

    // Realtime Database reference
    const db = getDatabase();

    // Function to handle login
    const login = async (email, password) => {
        setError(null);
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            setUser(userCredential.user);
            // Fetch user role after successful login
            await fetchUserRole(userCredential.user.uid);
        } catch (error) {
            setError(error.message); // Capture error message
            throw error; // Rethrow error for handling in Login component
        }
    };

    // Function to fetch user role from Realtime Database
// Fetching user role from Realtime Database
const fetchUserRole = async (uid) => {
    const userRef = ref(db, `users/${uid}`);
    try {
        const snapshot = await get(userRef);
        if (snapshot.exists()) {
            const userData = snapshot.val();
            setRole(userData.role); // Ensure this is correctly set
            console.log("Fetched User Role:", userData.role); // Debugging log
        } else {
            console.log('No such user document!');
        }
    } catch (error) {
        console.error('Error fetching user role:', error);
    }
};


    // Function to handle logout
    const logout = async () => {
        setError(null);
        try {
            await signOut(auth);
            setUser(null); // Reset user state on logout
            setRole(null); // Reset role state on logout
        } catch (error) {
            setError(error.message);
        }
    };

    // Monitor authentication state
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
            console.log("Current User:", currentUser); // Log the current user for debugging
            setUser(currentUser);
            if (currentUser) {
                await fetchUserRole(currentUser.uid); // Fetch user role when the user state changes
            }
            setLoading(false); // Set loading to false once user state is checked
        });

        return () => unsubscribe(); // Cleanup subscription on unmount
    }, );

    // Provide context value
    const value = {
        user,
        role, // Include role in the context value
        login,
        logout,
        error,
        loading,
    };

    return (
        <AuthContext.Provider value={value}>
            {!loading && children} {/* Render children only when not loading */}
        </AuthContext.Provider>
    );
};

// Custom hook for using AuthContext
export const useAuth = () => {
    return useContext(AuthContext);
};
